import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import { getCognitoId } from 'components/util/UserId'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Api from 'state/apis/api'

enum SocialPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  TikTok = 'TikTok'
}

interface Item {
  itemText: string
  itemUrl: string
  itemColor: string
}

interface Section {
  sectionHeading: string
  sectionHeadingColor: string
  items: Item[]
}

interface SocialItem {
  type: SocialPlatform
  href: string
}

interface Props {
  backgroundColor: string
  backgroundImage: any
  backgroundImageMobile: any
  logo: any
  sections: Section[]
  newsLetterButtonBackgroundColor: string
  newsLetterButtonTextColor: string
  newsLetterButtonText: string
  socials: SocialItem[]
  socialIconColor: string
  borderColor: string
  isLastName: boolean
  isFirstName: boolean
  newsletterHeading: string
  newsletterDescription: string
  newsletterDescriptionColor: string
  newsletterHeadingColor: string
  formFieldbg: string
  formFieldTextColor: string
  cloudinaryCertifyImage: any
  builderCertifyImage: any
  cloudinaryEuFundingImage: any
  builderEuFundingImage: any
  contentText: string
  isEUFunding: boolean
  euFundingImage: { desktopSrc: any; mobileSrc: any; alt: string }
  footerText: string
  addressText: string
}

export const Footer = ({
  backgroundColor,
  backgroundImage = '',
  backgroundImageMobile,
  logo,
  sections,
  newsLetterButtonBackgroundColor,
  newsLetterButtonTextColor,
  newsLetterButtonText,
  socials,
  socialIconColor,
  borderColor,
  isFirstName,
  isLastName,
  newsletterHeading,
  newsletterDescription,
  newsletterDescriptionColor,
  newsletterHeadingColor,
  formFieldbg,
  formFieldTextColor,
  cloudinaryCertifyImage,
  builderCertifyImage,
  cloudinaryEuFundingImage,
  builderEuFundingImage,
  contentText,
  isEUFunding,
  euFundingImage,
  footerText,
  addressText
}: Props) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: ''
  })
  const [errorToast, setErrorToast] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [cognitoId, setCognitoId] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const callIdsForGtag = async () => {
      setCognitoId(await getCognitoId())
    }
    callIdsForGtag()
  }, [])

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const { email, firstName: name, lastName: surname } = formData
    Api.post('wwwREST', '/v1/public/email/signup', {
      body: {
        email,
        name,
        surname
      }
    })
      .then(() => {
        setFormData({
          email: '',
          firstName: '',
          lastName: ''
        })
        setShowToast(true)
      })
      .catch(() => {
        setErrorToast(true)
      })
  }
  console.log('== footer basic info', sections, socials)
  const mapColOne = sections[0]
  const mapColTwo = sections[1]
  const mapColThree = sections[2]
  const mapColFour = sections[3]

  const getSocialIcon = (item: SocialItem) => {
    if (item.type === SocialPlatform.Facebook) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58172 0 0 3.58172 0 8V20C0 24.4183 3.58172 28 8 28H10.7505V27.6362V17.4575H8.27801C8.07705 17.4575 7.91421 17.2946 7.91421 17.0937V13.3943C7.91421 13.1933 8.07705 13.0305 8.27801 13.0305H10.8736V10.2836C10.8218 9.98619 10.6054 8.27066 12.1626 6.83787C13.568 5.54493 15.3472 5.44444 15.8591 5.44444C15.9272 5.44444 15.9734 5.44612 15.9942 5.44714H18.9442C19.1452 5.44714 19.308 5.60997 19.308 5.81094V9.25059C19.308 9.45148 19.1452 9.61438 18.9442 9.61438H16.8036C16.0516 9.61438 15.6703 9.97345 15.6703 10.6816V13.0305H18.9442C19.0479 13.0305 19.1467 13.0748 19.2158 13.1523C19.2848 13.2296 19.3174 13.3329 19.3056 13.436L18.8739 17.1832C18.8528 17.3668 18.6973 17.5055 18.5125 17.5055H15.6702V27.6362V28H20C24.4183 28 28 24.4183 28 20V8C28 3.58172 24.4183 0 20 0H8Z"
            fill="#006685"
          />
        </svg>
      )
    }

    if (item.type === SocialPlatform.Instagram) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path
            d="M7.77778 0C3.48289 0 0 3.48289 0 7.77778V20.2222C0 24.5171 3.48289 28 7.77778 28H20.2222C24.5171 28 28 24.5171 28 20.2222V7.77778C28 3.48289 24.5171 0 20.2222 0H7.77778ZM23.3333 3.11111C24.192 3.11111 24.8889 3.808 24.8889 4.66667C24.8889 5.52533 24.192 6.22222 23.3333 6.22222C22.4747 6.22222 21.7778 5.52533 21.7778 4.66667C21.7778 3.808 22.4747 3.11111 23.3333 3.11111ZM14 6.22222C18.2949 6.22222 21.7778 9.70511 21.7778 14C21.7778 18.2949 18.2949 21.7778 14 21.7778C9.70511 21.7778 6.22222 18.2949 6.22222 14C6.22222 9.70511 9.70511 6.22222 14 6.22222ZM14 9.33333C12.7623 9.33333 11.5753 9.825 10.7002 10.7002C9.825 11.5753 9.33333 12.7623 9.33333 14C9.33333 15.2377 9.825 16.4247 10.7002 17.2998C11.5753 18.175 12.7623 18.6667 14 18.6667C15.2377 18.6667 16.4247 18.175 17.2998 17.2998C18.175 16.4247 18.6667 15.2377 18.6667 14C18.6667 12.7623 18.175 11.5753 17.2998 10.7002C16.4247 9.825 15.2377 9.33333 14 9.33333Z"
            fill="#006685"
          />
        </svg>
      )
    }

    if (item.type === SocialPlatform.TikTok) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58172 0 0 3.58172 0 8V20C0 24.4183 3.58172 28 8 28H20C24.4183 28 28 24.4183 28 20V8C28 3.58172 24.4183 0 20 0H8ZM15.7157 3.89425C15.111 3.89507 14.5052 3.89589 13.8974 3.9053L13.898 3.9059C13.882 5.99432 13.883 8.08323 13.884 10.1747C13.8846 11.3438 13.8852 12.5136 13.8828 13.6846C13.8801 14.0773 13.8805 14.4695 13.8809 14.8615C13.882 15.9163 13.8831 16.9696 13.8251 18.0262C13.8166 18.3194 13.6695 18.5772 13.5267 18.8275C13.5153 18.8475 13.5039 18.8674 13.4927 18.8872C13.0212 19.6559 12.154 20.1809 11.2493 20.1906C9.88452 20.3115 8.60605 19.1941 8.41343 17.8646C8.41197 17.7994 8.40967 17.7339 8.40736 17.6681C8.39418 17.2931 8.38074 16.9106 8.52341 16.5612C8.72636 15.9852 9.11586 15.476 9.6287 15.1436C10.3311 14.6556 11.2705 14.5827 12.0744 14.8428C12.0744 14.3303 12.0832 13.818 12.092 13.3058C12.1037 12.6185 12.1154 11.9313 12.106 11.2437C10.3463 10.9144 8.4669 11.471 7.12523 12.6395C5.94155 13.6427 5.17896 15.1174 5.03313 16.659C5.01672 17.0546 5.02341 17.4593 5.04285 17.8646C5.21056 19.7604 6.36204 21.5268 7.99841 22.4918C8.98582 23.0739 10.1434 23.3898 11.3021 23.3236C13.1913 23.292 15.0306 22.2791 16.0903 20.7253C16.749 19.792 17.1227 18.6648 17.1859 17.5322C17.2024 15.9763 17.2013 14.4174 17.2003 12.8567C17.1998 12.0526 17.1992 11.2479 17.2011 10.4429C17.6222 10.7181 18.0524 10.9873 18.5142 11.1964C19.5745 11.6995 20.7485 11.9425 21.917 11.9808V8.7184C20.6701 8.57925 19.3886 8.16788 18.4826 7.26918C17.5748 6.39236 17.1288 5.12908 17.065 3.88889C16.6156 3.89304 16.166 3.89365 15.7157 3.89425Z"
            fill="#006685"
          />
        </svg>
      )
    }
  }

  const FirstNameComp = () => {
    if (!isFirstName) return null
    return (
      <>
        <label htmlFor="first-name" className="sr-only">
          First Name
        </label>
        <input
          type="text"
          name="first-name"
          id="first-name"
          required
          className="text-primary-900 placeholder-primary-500 w-full min-w-0 appearance-none rounded-3xl border-gray-300 bg-white py-2 px-4 text-base focus:outline-none md:w-1/2"
          style={{ color: formFieldTextColor }}
          placeholder="First Name"
          value={formData.firstName}
          onChange={e => {
            setFormData({ ...formData, firstName: e.target.value })
          }}
        />
      </>
    )
  }

  const LastNameComp = () => {
    if (!isLastName) return null
    return (
      <>
        <label htmlFor="last-name" className="sr-only">
          Last Name
        </label>
        <input
          type="text"
          name="last-name"
          id="last-name"
          required
          className="text-primary-900 placeholder-primary-500 w-full min-w-0 appearance-none rounded-3xl border-gray-300 bg-white py-2 px-4 text-base focus:outline-none md:w-1/2"
          style={{ color: formFieldTextColor }}
          placeholder="Last Name"
          value={formData.lastName}
          onChange={e => {
            setFormData({ ...formData, lastName: e.target.value })
          }}
        />
      </>
    )
  }

  return (
    <footer
      className="bg-white"
      aria-labelledby="footer-heading"
      style={{
        backgroundColor,
        backgroundImage: isMobile
          ? backgroundImageMobile
            ? `url(${backgroundImageMobile})`
            : ''
          : backgroundImage
            ? `url(${backgroundImage})`
            : '',

        backgroundRepeat: 'no-repeat',

        backgroundSize: 'cover',
        backgroundPosition: 'top center'
      }}
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="flex items-center justify-between gap-2">
          <div className="md:w-1/5">
            <StaticImage
              className="w-full max-w-sm"
              src="../../../images/DD_christmas_logo_website_2024.svg"
              alt="Different Dog"
              style={{ maxWidth: '200px' }}
              placeholder="blurred"
            />
          </div>
        </div>

        <div className="mt-8 flex flex-col justify-between gap-8 md:flex-row">
          <div className="flex w-2/5 flex-row justify-between md:flex-col">
            <div className="text-black">
              <p>Talk to us:</p>
              <a href="tel:+441743384562" className="block font-bold">
                01743 384 562
              </a>
              <a href="mailto:feedme@differentdog.com" className="block font-bold">
                feedme@differentdog.com
              </a>
              {addressText && (
                <div className="mt-6">
                  <p>{addressText}</p>
                </div>
              )}
            </div>
            <div className="flex-end ml-4 flex flex-col space-x-0 space-y-6 md:ml-0 md:mt-8 md:flex-row md:space-y-0 md:space-x-6">
              {socials?.map((item, i) => (
                <Link key={i} to={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.type}</span>
                  {getSocialIcon(item)}
                </Link>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            <div>
              <p
                className="text-sm font-sans font-semibold tracking-wider text-gray-900 sm:text-lg"
                style={{ color: mapColOne?.sectionHeadingColor }}
              >
                {mapColOne?.sectionHeading}
              </p>
              <ul role="list" className="mt-4 space-y-2">
                {mapColOne?.items?.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.itemUrl}
                      className="text-sm text-gray-500 hover:text-gray-900 sm:text-base"
                      style={{ color: item.itemColor }}
                    >
                      {item.itemText}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p
                className="text-sm font-sans font-semibold tracking-wider text-gray-400 sm:text-lg"
                style={{ color: mapColTwo?.sectionHeadingColor }}
              >
                {mapColTwo?.sectionHeading}
              </p>
              <ul role="list" className="mt-4 space-y-2">
                {mapColTwo?.items?.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.itemUrl}
                      className={`text-sm text-gray-500 hover:text-gray-900 sm:text-base ${!cognitoId && item.itemUrl.includes('account') ? 'hidden' : 'block'}`}
                      style={{ color: item.itemColor }}
                    >
                      {item.itemText}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p
                className="text-sm font-sans font-semibold tracking-wider text-gray-400 sm:text-lg"
                style={{ color: mapColThree?.sectionHeadingColor }}
              >
                {mapColThree?.sectionHeading}
              </p>
              <ul role="list" className="mt-4 space-y-2">
                {mapColThree?.items?.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.itemUrl}
                      className="text-sm text-gray-500 hover:text-gray-900 sm:text-base"
                      style={{ color: item.itemColor }}
                    >
                      {item.itemText}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* className="mt-12 md:mt-0" */}
            <div>
              <p
                className="text-sm font-sans font-semibold tracking-wider text-gray-400 sm:text-lg"
                style={{ color: mapColFour?.sectionHeadingColor }}
              >
                {mapColFour?.sectionHeading}
              </p>
              <ul role="list" className="mt-4 space-y-2">
                {mapColFour?.items?.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.itemUrl}
                      className="text-sm text-gray-500 hover:text-gray-900 sm:text-base"
                      style={{ color: item.itemColor }}
                    >
                      {item.itemText}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* { newsletter row } */}
        <div
          className="mb-4 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0"
          // style={{ borderColor: borderColor }}
        >
          <div>
            <h3 className="text-base text-xl tracking-wider text-gray-400" style={{ color: newsletterHeadingColor }}>
              {newsletterHeading}
            </h3>
            <p className="mt-2 text-sm text-gray-500" style={{ color: newsletterDescriptionColor }}>
              {newsletterDescription}
            </p>
          </div>
        </div>

        <form className="mt-4 lg:mt-0" onSubmit={handleSubmit}>
          <div className="flex w-full flex-col gap-4 sm:flex-row md:justify-between md:gap-4">
            <div className="flex w-full gap-4 lg:w-1/2 lg:flex-shrink-0">
              {FirstNameComp()}
              {LastNameComp()}
            </div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              className="w-full min-w-0 appearance-none rounded-3xl border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none"
              placeholder="Enter your email"
              value={formData.email}
              style={{ color: formFieldTextColor }}
              onChange={e => {
                setFormData({ ...formData, email: e.target.value })
              }}
            />
            <div className="rounded-full sm:flex-shrink-0">
              <button
                type="submit"
                // rounded-full
                className="flex w-full items-center justify-center rounded-full border border-transparent bg-indigo-600 py-3 px-6 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                style={{ backgroundColor: newsLetterButtonBackgroundColor, color: newsLetterButtonTextColor }}
              >
                {newsLetterButtonText}
              </button>
            </div>
          </div>
        </form>
        <div className="mx-auto mt-10 border-t border-[#b2b0b0]" style={{ borderColor: '#c6c6c6' }}></div>
        {isEUFunding && (
          <div className="mt-10 flex justify-between">
            <div className="flex flex-col gap-8 md:flex-row">
              <div className="md:w-1/4">
                <CdnImage
                  className="h-auto !max-w-[75%] md:!max-w-full sm:w-auto"
                  url={builderEuFundingImage}
                  alt={cloudinaryEuFundingImage?.altText}
                  // steps={[100, 300, 600, 800]}
                />
              </div>
              <div className="prose prose-indigo w-3/4 text-gray-500 md:w-2/3">
                <p className={`enable-list-styles text-xs`} dangerouslySetInnerHTML={{ __html: contentText }}></p>
              </div>
            </div>
            <div>
              <CdnImage
                className="h-auto max-h-[100%] w-18 max-w-[100%] md:h-24"
                url={builderCertifyImage}
                alt={cloudinaryCertifyImage?.altText}
                // renderPlaceholder={false}
                steps={[100, 300]}
              />

              {/* {builderCertifyImage ? (
                <CdnImage
                  className="h-auto max-h-[100%] w-18 max-w-[100%] md:h-24"
                  url={builderCertifyImage}
                  alt={cloudinaryCertifyImage?.altText}
                  // renderPlaceholder={false}
                  steps={[100, 300]}
                />
              ) : (
                <CloudinaryImage
                  className="h-auto max-h-[100%] w-18 max-w-[100%] md:h-24"
                  publicId={cloudinaryCertifyImage?.public_id}
                  alt={cloudinaryCertifyImage?.altText}
                  // renderPlaceholder={false}
                  steps={[100, 300]}
                />
              )} */}
            </div>
          </div>
        )}
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: footerText }} />

        <ErrorToast
          showToast={errorToast}
          msg={'Please enter valid email address'}
          handleSetShowToast={handleSetErrorToast}
          handleOnClick={() => {}}
        />
        <Toast showToast={showToast} msg={'Thank you for subscribing'} handleSetShowToast={handleSetShowToast} />
      </div>
    </footer>
  )
}

export default Footer
